<template>
    <div
        class="b-modal"
        :class="{ 'has-no-padding': hasNoPadding || document, 'b-modal--document': document }"
    >
        <div class="b-modal__mask"></div>
        <div
            class="b-modal__container"
            :style="modalStyle"
        >
            <spin-loader
                v-if="isLoading"
                customClass="c-spin-loader-overlay"
            >
            </spin-loader>
            <span
                class="b-modal__close"
                :class="[
                    { 'b-modal__close--backtrace': hasBacktrace },
                    (document ? `u-primary-color__bg` : `u-primary-color__text u-hover--grow`)
                ]"
                @click="close"
            >
                <i
                    class="fa-times"
                    :class="document ? 'fal' : 'far'"
                >
                </i>
            </span>
            <span
                v-if="hasBacktrace"
                class="b-modal__close b-modal__back u-primary-color__bg"
                :class="{ 'u-hover--grow': !document }"
                @click="back"
            >
                <i class="far fa-arrow-left">
                </i>
            </span>
            <div
                id="modal_header"
                class="b-modal__header"
            ></div>
            <div
                ref="innerModal"
                class="b-modal__inner"
            >
                <slot></slot>
            </div>
            <div
                id="modal_footer"
                class="b-modal__footer"
            ></div>
        </div>
    </div>
</template>

<script>

import modelEventBus from '@js/nal/events/modelEvents.js';

export default {
    name: 'ModalComponent',
    provide: {
        isInModal: true,
    },
    emits: ['back', 'close'],
    props: {
        modalStyle: {
            type: Object,
            default: () => ({}),
            validator: (value) => {
                if (!(value instanceof Object)) {
                    return false;
                }

                const allowedProperties = ['width', 'height'];
                const valueProperties = Object.keys(value);

                // Check if the object only contains the properties 'width' and/or 'height'
                const isValid = valueProperties.every(prop => allowedProperties.includes(prop));

                if (!isValid) {
                    const disallowedProperties = valueProperties.filter(prop => !allowedProperties.includes(prop));
                    // eslint-disable-next-line no-console
                    console.warn('modalStyle contains disallowed properties:', disallowedProperties);
                }

                return isValid;
            },
        },
        document: Boolean,
        hasNoPadding: Boolean,
        isLoading: Boolean,
    },
    data() {
        return {
            removeEscapeListener: null,
            currentModalView: null,
        };
    },
    computed: {
        hasBacktrace() {
            return !!(this.nal.modal?.backtrace || []).length;
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        back() {
            this.$emit('back');
        },
        closeHandler() {
            this.hasBacktrace ? this.back() : this.close();
        },
        saveScrollPositionOfPreviousModal() {
            const backtrace = this.nal.modal.backtrace;
            const isPreviousModal = this.nal.modal.scrollTop !== undefined;

            //Don't want to update last backtrace if we've gone back to a previous modal
            if (!isPreviousModal && backtrace?.length && this.$refs.innerModal) {
                backtrace[backtrace.length - 1].scrollTop = this.$refs.innerModal.scrollTop;
            }
        },
        applyScrollPosition() {
            this.$refs.innerModal.scrollTo(0, this.nal.modal.scrollTop || 0);
        },
    },
    watch: {
        $route() {
            this.close();
        },
    },
    created() {
        this.removeEscapeListener = window.handleEscapeListener(this.close);
        document.addEventListener('close-modal', this.closeHandler, false);
    },
    mounted() {
        const scrollBarWidth = window.innerWidth - parseInt(getComputedStyle(document.getElementById('app')).width);
        const bodyStyle = document.documentElement.style;
        bodyStyle.setProperty('padding-right', `${scrollBarWidth}px`);
        bodyStyle.setProperty('overflow', 'hidden');

        modelEventBus.$on('modalToTop', () => {
            if (this.$refs.innerModal) {
                this.$refs.innerModal.scrollTop = 0;
            }
        });
    },
    unmounted() {
        this.removeEscapeListener();
        document.removeEventListener('close-modal', this.closeHandler);
        const bodyStyle = document.documentElement.style;
        bodyStyle.removeProperty('padding-right');
        bodyStyle.removeProperty('overflow');
    },
    beforeUpdate() {
        this.saveScrollPositionOfPreviousModal();
    },
    updated() {
        if (this.currentModalView && this.currentModalView !== this.nal.modal.component && this.$refs.innerModal) {
            this.applyScrollPosition();
        }

        this.currentModalView = this.nal.modal.component;
    },
};

</script>

<style lang="scss" scoped>
@import "@sass/variables";
@import "@sass/mixins";

$padding: 20px;
$buttonGap: 45px;

.b-modal {
    background-color: rgba(0, 0, 0, .4);
    display: flex;
    inset: 0;
    justify-content: center;
    overflow: hidden auto;
    padding: 5vh calc(5vw - $buttonGap);
    position: fixed;
    z-index: z("modal");

    &__mask {
        inset: 0;
        position: fixed;
    }

    &__container {
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        display: flex;
        flex-direction: column;
        font-size: 15px;
        margin: 0 $buttonGap;
        max-height: 90vh;
        max-width: 90vw;
        position: relative;
        text-align: left;
        text-transform: none;
        width: 500px;
    }

    &__header {
        border-bottom: 1px solid $color__grey--lighter;
        padding: $padding;

        &:empty {
            display: none;
        }

        :deep(h2) {
            font-size: map-get($rem-sizes, 'xl');
            margin: 0;
        }
    }

    &__inner {
        flex: 1;
        overflow: hidden auto;
        padding: $padding;
        position: relative;
    }

    &__footer {
        border-top: 1px solid $color__grey--lighter;
        display: flex;
        gap: $padding;
        justify-content: flex-end;
        padding: $padding;

        &:empty {
            display: none;
        }

        > :deep(div) {
            margin: 0;
        }
    }

    &__close {

        @include circle;
        border: none;
        box-shadow: 0 1px 6px rgba(0, 0, 0, .2);
        font-size: 14px;
        position: absolute;
        right: -38px;
        top: 0;
        z-index: z("over");
    }

    &__back {
        color: #fff;
        top: 38px;
    }

    &.has-no-padding {
        .b-modal__inner {
            padding: 0;
        }
    }

    &--document {
        background-color: rgba(0, 0, 0, 0.7);
        overflow: hidden;
        padding: 61px 0 0 0;
        z-index: 12;

        .b-modal {
            &__container {
                align-items: center;
                background-color: transparent;
                box-shadow: none;
                display: flex;
                height: calc(100vh - 60px);
                justify-content: center;
                margin: 0;
                max-height: calc(100vh - 60px);
                max-width: 100vw;
                padding: 0;
                width: 100%;
            }

            &__close {
                background: rgba(0, 0, 0, 0.2);
                border-left: solid 1px rgba(0, 0, 0, 0.1);
                border-radius: 0;
                box-shadow: none;
                color: #fff;
                cursor: pointer;
                height: 60px;
                position: fixed;
                right: 0;
                top: 0;
                width: 60px;

                &:hover {
                    background: rgba(0, 0, 0, 0.3);
                }

                i {
                    font-size: 30px;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .b-modal {
        padding: calc(5vh - $buttonGap) 5vw 5vh;

        &__container {
            margin: $buttonGap 0 0;
            max-height: calc(90vh - $buttonGap);
        }

        &__close {
            right: 0;
            top: -38px;
        }

        &__back {
            right: 38px;
            top: -38px;
        }
    }

    .b-modal--document {
        padding: 41px 0 0 0;

        .b-modal {
            &__container {
                height: calc(100vh - 40px);
                max-height: calc(100vh - 40px);
            }

            &__close {
                height: 40px;
                width: 40px;

                i {
                    font-size: 24px;
                }
            }
        }
    }
}

</style>